<template>
  <WidgetLayout :payload="data"/>
</template>

<script>
import WidgetLayout from "@/components/WidgetLayout.vue"
import atteindre from '../../../public/json/decret-tertiaire-atteindre.json'
import slides from '../../../public/json/decret-tertiaire-slides.json'

export default {
  name: "reach-your-objectives",
  components: {
    WidgetLayout
  },
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'Découvrez comment animer votre démarche et suivre de près la réduction de vos consommations énergétiques pour répondre au décret tertiaire.'
    }]
  },
  computed: {
    data() {
      return atteindre.map(m => {
        if (m.type === 'slider') {
          return {
            slides: slides,
            ...m
          }
        } else {
          return {
            ...m
          }
        }
      })
    }
  }
}
</script>